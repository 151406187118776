
/*
//////////////////////////////////////////

           Client Main Page

////////////////////////////////////////
*/

.client-center{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.client-wrapper{

    width: 55%;
}

.card-wrapper{

    /* width: 50%; */
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 1600px){

    .client-wrapper{

        width: 70%;
    }

    /* .card-wrapper{

        width: 60%;
    } */
}

@media only screen and (max-width: 1440px){

    .client-wrapper{

        width: 70%;
    }

    /* .card-wrapper{

        width: 60%;
    } */
}

@media only screen and (max-width: 1200px){

    .client-wrapper{

        width: 80%;
    }

    /* .card-wrapper{

        width: 60%;
    } */

    .client-cal-row .col-s-12{

        padding-left: 0 !important;
        padding-right: 0 !important;
        border-right: none !important;
    }

    .client-cal-select-title{

        text-align: center !important;
    }

    .client-cal-select-month{

        text-align: center !important;
        margin-bottom: 20px;
    }

}

.client-card{

    margin-top: 10%;
    margin-bottom: 10%;
    width: 100%;
    position: relative;
    padding: 40px;
    background: #fff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.company-avatar-outer{

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    width: 166px;
    height: 166px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}

.company-avatar-outer.incard{

    top: -92px;
}

.company-avatar-inner{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 93%;
    height: 93%;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.company-avatar-inner img{

    width: 100%;
    height: 100%;
}


.client-appoint-card{
    
    position: relative;
    display: flex;
    align-items: center;
    /* margin-top: 20px; */
    width: 100%;
    padding: 20px;
}

.client-appoint-info{

    position: relative;
    top: 50%;
    transform: translateY(-50%);
    float: right;
}

.appoint-go{
    
    position: relative;
    /* top: 50%;
    transform: translateY(-50%); */
    font-size: 40px;
    color: #323232;
    cursor: pointer;
}



.client-appoint-container{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.client-appoint-wrapper{

    width: 45%;
}

.client-schedule-card{

    position: relative;
    margin-top: 10%;
    width: 100%;
    padding: 40px 40px 100px 40px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}

.client-appoint-wrapper.expanded{

    width: 60%;
}

.client-appoint-wrapper.data-reg{

    width: 50%;
}

@media only screen and (max-width: 1600px){

    .client-appoint-wrapper.data-reg{

        width: 60%;
    }

    .client-appoint-wrapper{

        width: 65%;
    }

    .client-appoint-wrapper.expanded{

        width: 70%;
    }
    
} 

@media only screen and (max-width: 1440px){

    .client-appoint-wrapper.data-reg{

        width: 70%;
    }

    .client-appoint-wrapper{

        width: 65%;
    }

    .client-appoint-wrapper.expanded{

        width: 80%;
    }
    
} 

@media only screen and (max-width: 1200px){

    .client-appoint-wrapper.data-reg{

        width: 80%;
    }

    .client-data-reg-row .col-s-12{

        border: none !important;
        padding: 0 !important;
    }

    .client-data-datele-mele-title{

        margin-top: 20px !important;
    }
}


/*
//////////////////////////////////////////

          Client Appointment

////////////////////////////////////////
*/


.appoint-back{

    position: absolute;
    top: 20px;
    right: 60px;
    color: #44A5FF !important;
    cursor: pointer;
}

.appoint-avatar{

    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    object-fit: cover;
}

.appoint-avatar img{

    width: 100%;
    height: 100%;
}

.appoint-title{

    color: #455A64;
    text-align: left;
}

.appoint-calendar{

    width: 100%;
    height: 400px;
    /* background: #455A64; */
}

.select-hour{

    margin-top: 10px;
    cursor: pointer;
    width: 100%;
    padding: 6.5px;
    background: #F4F4F4;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
}

.select-hour.selected{

    background: #FFC727;
    color: #fff !important;
    padding: 10px;
}

.appoint-desc{

    position: relative;
    padding: 20px 15px 20px 15px;
    width: 100%;
    height: 125px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid rgb(224, 224, 224);
    background-color: #FAFAFA;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.appoint-desc:focus{

    outline: none;
}

.appoint-desc-label{

    position: absolute;
    top: 1px;
    left: 15px;
    font-size: 10px;
    font-weight: 400;
    color: rgb(144, 144, 144);
}

/*
//////////////////////////////////////////

        Client Appointment Summary

////////////////////////////////////////
*/

.summary-info-row{

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.client-details{

    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

@media only screen and (max-width: 1440px){

    .client-details{

        width: 50%;
    }
}

/*
//////////////////////////////////////////

        Termeni si conditii

////////////////////////////////////////
*/

.tos-wrapper{

    margin: 20px auto;
    width: 75%;
    text-align: left;
}

.tos-logo-tx, .tos-h-title, .tos-h-subtitle, .tos-paragraph{

    margin: 0;
    color: #263238;
    font-weight: 500;
}

.tos-logo-tx{

    margin-left: 10px;
    font-size: 28px;
}

.tos-h-title{

    font-size: 40px;
}

.tos-h-subtitle{

    font-size: 28px;
}

.tos-paragraph{

    font-size: 16px;
    white-space: pre-wrap;
    font-weight: 400;
}

@media only screen and (max-width: 1200px){

    .tos-wrapper{

        margin: 0px auto;
        padding: 20px;
        width: 100%;
        text-align: left;
    }
}


/*

/////////////////////////////////////////////////////////////////////////////////////////////////////////////

*/

/*
//////////////////////////////////////////

                MOBILE

////////////////////////////////////////
*/


/*
//////////////////////////////////////////

           Client Main Page

////////////////////////////////////////
*/

.company-avatar-outer.mobile{
    
    top: 20px;
    width: 110px;
    height: 110px;
}

.m-client-header{

    padding: 0 30px 0 30px;   
}

.m-client-header .title{

    margin-top: 30px;
    font-size: 24px;
}

.m-client-services-card{

    margin-top: 30px;
    width: 100%;
    padding: 40px 30px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.m-calendar{

    margin-top: 20px;
    width: 100%;
    min-height: 350px;
    /* background: lavender; */
}

.m-select-hour-container{

    padding: 30px;
}

.m-select-hour-container .select-hour{

    font-weight: 500;
    padding: 12px;
}

.m-select-hour-container .select-hour.selected{

    padding: 22px;
}

.m-appoint-back{

    color: #44A5FF !important;
}

