/*
////////////////////////////////////////////

                    Icons

///////////////////////////////////////////
*/


.article-icon path{

    fill: #A6A6A6;
}

.terms-icon{

    width: 16px;
    height: 16px;
}

.terms-icon path{

    fill: #FFC727;
}

.zoom-icon-disabled path{

    fill: #C7C7C7 !important;
}

.loc-icon-desktop-card path{

    fill: #a6a6a6 !important;
}

.loc-icon-dark path{

    fill: #777777 !important;
}

.loc-icon-darker path{

    fill: #455A64 !important;
}

.loc-icon-desktop-card.disabled path{

    fill: #C7C7C7 !important;
}

/*
////////////////////////////////////////////

            Dashboard Calendar

///////////////////////////////////////////
*/


/*////Popups///////*/


.calendar-modal-container{

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Stay in place */
    z-index: 9999999; /* Sit on top */
    padding-top: 20px;
    padding-bottom: 20px;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    overflow-y: auto;
}

.calendar-popup-card{

    position: relative;
    margin: auto;
    padding: 20px 10px 20px 10px;
    width: 600px;
    /* max-height: 630px; */
    border-radius: 10px;
    background-color: #fefefe;
    z-index: 9999999; 
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    overflow-wrap: break-word;
}

.popup-appoint-title{

    display: flex;
    width: 100%;
}

.popup-appoint-title-left{

    width: 92%;
}

/* .popup-appoint-title-right{

    width: 6%;
} */

/*

////Calendar//////

*/

.dashboard-calendar-card{

    width: 100%;
    padding: 30px 0 0 0;
    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
}

.dashboard-calendar-header{

    display: flex;
    align-items: center;
    width: 100%;
}

.calendar-header-title{

    display: flex;
    align-items: center;
    width: 35%;
}

.header-appoint{

    display: flex;
    align-items: center;
    width: 10%;
}

.date-changer{

    display: flex;
    align-items: center;
    width: 20%;
}

.calendar-date-change{

    justify-content: flex-end;
}

.add-to-calendar{

    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background: #31A81D;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
}


.calendar-type{

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 35%;
}

.calendar-type-select{

    margin: 0;
    margin-left: 20px;
    font-size: 16px;
    color: #455A64;
    cursor: pointer;
}

.calendar-type-select:nth-child(3){

    margin-right: 30px;
}

.calendar-type-select.c-selected{

    color: #44A5FF;
    font-weight: 700;
} 


/* .date-changer{  

    position: relative;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.add-to-calendar{

    display: inline-block;

    position: absolute;
    top: -8px;

    left: 30%;
    width: 35px;
    height: 35px;
    background: #31A81D;
    border-radius: 50%;
    cursor: pointer;
}


.calendar-type {

    position: absolute;
    top: 2px;
    right: 30px;
}

.calendar-type-select{

    display: inline-block;
    position: relative;
    margin: 0;
    margin-left: 20px; 
    font-size: 16px;
    color: #455A64;
    cursor: pointer;
}

.calendar-type-select.c-selected{

    color: #44A5FF;
    font-weight: 700;
} */


/* .current-day-appointment:first-child{

    margin-top: 25px;
} */

@media only screen and (max-width: 1200px){

    .dashboard-cal-row .col-s-12{

        padding-right: 0 !important;
        border-right: none !important;
    }
}

/*
////////////////////////////////////////////

           Dashboard Services

///////////////////////////////////////////
*/


/* .services-container{

    background-color: blueviolet;
} */

.services-card{

    position: relative;
    margin-right: 20px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 5px; 
    background-color: #fff;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
}

.services-card .header{

    display: flex;
    justify-content: space-between;
    color: rgb(69, 90, 100);
}


.services-card .header .color, 
.services-card .header .title,
.services-card .header .settings-icon{

    display: inline-block;
    vertical-align: middle;
}

.services-card .header .color{

    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: green;
}

.services-card .header .title{

    margin: 0;
    
}

.services-card .header .settings-icon{

    font-size: 22px;
    cursor: pointer;
}

/* .services-card .info{

    display: flex;
} */



.services-card .info .info-text{

    margin: 0;
    text-align: left;
}

.services-card .info .schedule{

    margin: 0;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(69, 90, 100);
    text-align: left;

}

.services-card .separator-line{

    width: 100%;
    height: 1px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: rgb(221, 221, 221);
}

.services-card .footer{

    width: 90%;
    display: flex;
    position: absolute;
    bottom: 20px;
    justify-content: space-between;
    
}

.services-card .footer .link{
    
    margin: 0;
    color: rgb(68, 165, 255);
    cursor: pointer; 
}


@media only screen and (max-width: 1200px)  {
    
    .services-card{

        margin-right: 0;
        height: auto;
    }

    .services-card .footer{

        position: relative;
        width: 100%;
    }

    .services-card .footer.mobile{

        bottom: 0px;
    }
}

.disabled-sv-card{

    position: relative;
}

.reactivate-btn{

    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 999;
    transition: 0.2s ease-out;
    opacity: 0;
    cursor: pointer;
    color: #44A5FF;
    font-weight: 500;
    font-size: 18px;
}

.reactivate-btn.mobile{

    opacity: 1;
}

.disabled-sv-card:hover .reactivate-btn{

    opacity: 1;
}


/*
////////////////////////////////////////////

        Dashboard Service Details

///////////////////////////////////////////
*/

.service-details-card{

    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
}

.service-details-card .title{

    font-size: 18px;
}

@media only screen and (max-width: 1366px){

    .service-details-card .title{

        font-size: 15px;
    }
    
}

.service-details-card .row .col-l-4:nth-child(1),
.service-details-card .row .col-m-4:nth-child(1){

    padding-right: 20px;
}

.service-details-card .row .col-l-4:nth-child(2),
.service-details-card .row .col-m-4:nth-child(2)
{

    padding-left: 20px;
    padding-right: 20px;
    border-left: 1px solid rgb(224, 224, 224);
    border-right: 1px solid rgb(224, 224, 224);
}

.service-details-card .row .col-l-4:nth-child(3),
.service-details-card .row .col-m-4:nth-child(3){

    padding-left: 20px;
}

@media only screen and (max-width: 1200px){

    .service-details-card .row .col-s-12:nth-child(1),
    .service-details-card .row .col-s-12:nth-child(1),
    .service-details-card .row .col-s-12:nth-child(2),
    .service-details-card .row .col-s-12:nth-child(2),
    .service-details-card .row .col-s-12:nth-child(3),
    .service-details-card .row .col-s-12:nth-child(3){

        padding-left: 0;
        padding-right: 0;
        padding-bottom: 20px;
    }

    .service-details-card .row .col-s-12:nth-child(2),
    .service-details-card .row .col-s-12:nth-child(2),
    .service-details-card .row .col-s-12:nth-child(3),
    .service-details-card .row .col-s-12:nth-child(3){

        padding-top: 20px;
    }

    .service-details-card .row .col-s-12:nth-child(1),
    .service-details-card .row .col-s-12:nth-child(1),
    .service-details-card .row .col-s-12:nth-child(2),
    .service-details-card .row .col-s-12:nth-child(2){

        border-bottom: 1px solid rgb(224, 224, 224);
    }

    .service-details-card .row .col-s-12:nth-child(2){

        border-left: none;
        border-right: none;
    }
}

.period-selector,.m-period-selector{

    
    position: relative;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 10px 15px 5px 15px;
    border-radius: 5px;
    border: 1px solid rgb(224, 224, 224);
    background-color: #FAFAFA;
}

/* .period-selector:nth-child(2){

    left: 50%;
} */

.m-period-selector{

    position: relative;
    left: 0;
}

.period-selector:focus, .m-period-selector:focus{

    outline: none;
}


/* .m-period-selector:nth-child(2){

    top: 60px;
} */

.money-type{
    
    /* background-color: #31A81D; */
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    width: 36px;
    color: rgb(170, 170, 170);
    font-size: 20px;
    
}

.input-radio{

    position: relative;
    cursor: pointer;
    width: 14px;
    height: 14px;
    appearance: none;
    /* border: 2px solid #000;
    border-radius: 50%; */
    background: url("/src/assets/Vectorcheckmark-exterior.svg");
}

.input-radio::before{

    position: absolute;
    content:'';
    display:block;
    width: 8px;
    height: 8px;
    /* margin: 3px auto;   */
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.input-radio:checked::before{

    background: url("/src/assets/Vectorcheckmark-interior.svg");
}

.days-input{

    border-radius: 5px 0 0 5px;
}

.days-select{

    border-radius: 0 5px 5px 0;
}

.explanation{

    font-size: 14px;
    color: #455A64;
    width: 70%;
}

@media only screen and (max-width: 1200px){

    .explanation{

        width: 100%;
    }
}

.break-paragraph{

    color: #263238;
    font-size: 14px;
}

.schedule-card{
    
    width: 100%;
    /* height: 240px; */
    border-radius: 5px;
    padding: 20px;
    background-color: #FAFAFB;
}

.schd-tx{

    font-weight: 400;
    margin: 0;
    margin-bottom: 3px;
}

.colors-container{

    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.color-circle{

    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    cursor: pointer;
}

.selected-color{

    transform: scale(1.3);
}

.color-checked, .add-color{

    display: block;
    position: relative;
    color: #fff;
    margin: 6px auto;
}   

.delete-service{

    /* position: relative;
    left: 50%;
    transform: translateX(-50%); */
    margin: 0;
    margin-top: 10px;
    color: #DD6A57;
    cursor: pointer;
}

/*
////////////////////////////////////////////

            Service Schedule

///////////////////////////////////////////
*/

.schedule-select-card{

    display: flex;
    align-items: center;
    width: 100%;
    padding: 24px;
    background: #FAFAFA;
    border-radius: 5px;
}

.create-schedule-button{

    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background: #F8BC05;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    color: #fff;
    font-size: 25px;
}

.schedule-editor-wrapper{

    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.schedule-manager-list-container{

    padding-right: 16px;
}


/*
////////////////////////////////////////////

      Service Details Mobile Version

///////////////////////////////////////////
*/

.m-serv-details-card{

    position: relative;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.m-details-container{

    position: relative;
    display: flex;
    width: 100%;
    margin-top: 20px;
    border-top: 1px solid #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
    padding: 20px 0px 20px 0px;
}

.m-color{

    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #60D84C;
}

@media only screen and (max-width:1200px){

    .left-content-details{

        width: 91.66%;;
    }

    .right-content-details{

        width: 8.33%;
    }

}


/*
////////////////////////////////////////////

       Service Edit Pages Mobile 

///////////////////////////////////////////
*/

.m-add-service{

    position: absolute;
    top: 20px;
    right: 20px;
    color: #44A5FF;
    font-size: 26px;
}

.m-edit-s-card{

    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.m-location-left{
    
    width: 8.33%;
}

.m-location-right{
    
    width: 91.66%;
}

.loc-content-wrapper{

    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
}

.loc-left-half{

    display: flex;
    align-items: center;
    width: 70%;
}

.loc-icon-wrapper{

    width: 24px;
}

.loc-tx-wrapper{

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
}

.loc-right-half{

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
}

.loc-sync-wrapper{

    display: flex;
    text-align: center !important;
    align-items: center;
}

.m-colors-wrapper{

    display: flex;
    justify-content: space-between;
    align-items: center;
}


.m-disp-1, .disp-1{

    width: 8.33%;
}

.disp-2{

    width: 16.66%;
}

.disp-3{

    width: 75%;
}

.m-disp-2{

    /* width: 16.66%; */
    width: 25%;
}

.m-disp-3{

    /* width: 75%; */
    width: 66.66%;
}

.m-disp-lg{

    width: 91.66%;
}

.m-sched-card{

    width: 100%;
    background: #FAFAFA;
    padding: 20px;
}

.m-sched-card p{

    margin: 0;
    margin-top: 10px;
}

.m-description-wrapper{

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 82px;
    /* line-clamp: 3; */
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

/*
////////////////////////////////////////////

        Account Profile Desktop

///////////////////////////////////////////
*/


/*
////////////////////////////////////////////

        Mobile Menu Pages Card

///////////////////////////////////////////
*/

.m-company-setting-card{

    position: relative;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    text-align: left;
}

@media only screen and(max-width: 1200px){

    .bg-img-profile{

        top: 0px !important;
    }
}

/*
////////////////////////////////////////////

        Mobile Create Appointment

///////////////////////////////////////////
*/

.m-create-appointment-card{

    position: relative;
    width: 100%;
    padding: 40px 30px 40px 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

/*
////////////////////////////////////////////

            Account Subscription

///////////////////////////////////////////
*/

.accsub-header .acc-sub-icon{

    color: #455A64;
    font-size: 30px;
}

.accsub-header .acc-sub-tx{

    margin: 0;
    color: #455A64;
    font-size: 10px;
}

.accsub-header .acc-sub-tx.title{

    
    font-weight: 700;
    font-size: 24px;
}

.sub-info-section .tx{

    margin: 0;
    font-size: 14px;
}

.sub-benefits-section .tx{

    margin: 0;
    font-size: 14px;
    color: #455A64;
}

.sub-benefits-section .benefits-container.desktop{

    margin: 0 auto;
    width: 50%;
}

.sub-benefits-section .benefits-list{

    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.sub-benefits-section .benefits-list.desktop{

    align-items: center;
}

.sub-benefits-section .benefits-list .element{

    display: flex;
    align-items: center;
    text-align: left;
}

.sub-benefits-section .benefits-list .icon{
    
   width: 10px;
}

/*
////////////////////////////////////////////

            Payment History

///////////////////////////////////////////
*/

.payment-history-tx{

    margin: 0;
    font-size: 14px;
    color: #263238;
}

.payment-history-tx.fs16{

    font-size: 16px;
}


.payment-history-tx.fs12{

    font-size: 12px;
}

.ah-servicename{

    font-size: 14px;
}

@media only screen and (max-width: 1200px){

    .ah-servicename{

        font-size: 10px;
    }
}

/*
////////////////////////////////////////////

        Security and Notifications

///////////////////////////////////////////
*/

.showpass{

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    z-index: 999;
    font-size: 20px;
    color: #323232;
}

.security-tx{

    margin: 0;
    color: #777777;
}

.security-tx.px12{

    font-size: 12px;
}

.security-tx.px14{

    font-size: 14px;
}

.security-option-row{

    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*
////////////////////////////////////////////

                Team members 

///////////////////////////////////////////
*/
.separated-card .row .col-l-4,
.separated-card .row .col-m-4{

    padding-right: 20px;
}

.separated-card .row .col-l-5,
.separated-card .row .col-m-5{

    padding-left: 20px;
    border-left: 1px solid rgb(224, 224, 224);
}

/* .member-row .col-l-6:nth-child(1),
.member-row .col-m-6:nth-child(1){

    padding-right: 10px;
}

.member-row .col-l-6:nth-child(2),
.member-row .col-m-6:nth-child(2){

    padding-left: 10px;
} */

/*Calendar appointment element*/

.cal-element-tx-wrapper{

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* line-clamp: 3; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.statistics-tx{
    
    margin: 0;
    margin-top: 16px;
    font-size: 14px;
    color: #777777;
}

.statistics-tx.stat{

    margin: 0;
    font-weight: 700;
    font-size: 48px;
    color: #455A64;
}

.statistics-subsolTx{

    margin: 0;
    color: #777777;
    font-size: 12px;
}

.statistics-pie-wrapper{

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.stat-circle{

    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.stat-circle.web{

    background-color: #FFC727;
}

.stat-circle.fb{

    background-color: #52678F;
}

.stat-circle.yt{

    background-color: #F7A4A0;
}

.stat-circle.others{

    background-color: #7BDFFF;
}

.bounce-wrapper{

    margin-top: 16px;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
}

.stat-member-wrapper{

    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.member-stats{

    display: flex;
    align-items: center;
    width: 90%;
}

 .avatar-wrapper{

    display: flex;
    align-items: center;
    width: 68px;
}

.avatar-wrapper .avatar{

    width: 54px;
    height: 54px;
    background-color: #FFC727;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.stat-member-wrapper .member-stats .member-info{
    
    width: calc(100% - 68px);
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    text-align: left;
}

.stat-member-wrapper .member-stats .member-info .member-tx-info{
    
    width: 100%;
    display: flex;
    /* align-items: flex-start; */
    justify-content: space-evenly;
    flex-direction: column;
    text-align: left;
}

.stat-member-wrapper .member-stats .member-info .member-tx-info .tx{

    margin: 0;
    font-weight: 400;
}

.stat-member-wrapper .member-stats .member-info .member-tx-info .member-tx-stats {

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stat-member-wrapper .member-stats .member-info .member-tx-info .tx.name,
.stat-member-wrapper .member-stats .member-info .member-tx-info .member-tx-stats .tx.total{

    color: #263238;
}

.stat-member-wrapper .member-stats .member-info .member-tx-info .member-tx-stats .tx.appoints{

    color: #777777;
}

.stat-member-wrapper .member-stats .member-info .member-tx-info .member-tx-stats .tx.total,
.stat-member-wrapper .member-stats .member-info .member-tx-info .member-tx-stats .tx.appoints
{

    font-size: 10px;
}


.stat-member-wrapper .member-stats .member-info .member-tx-info .tx.name{

    font-size: 14px;
}

.stat-member-wrapper .access-wrapper{

    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
}

.stat-member-wrapper .access-wrapper .enter{

    position: relative;
    left: 6px;
    font-size: 26px;
    color: #44A5FF;
}

.stat-member-tx-role{

    margin: 0;
    color: #777777;
    font-size: 10px;
}

.statistics-desktop-card{

    margin-bottom: 16px;
    width: 98%;
    /* height: 650px; */
}

.statistics-graph-wrapper-desktop{

    margin: 0 auto;
    width: 450px;
}

/*
////////////////////////////////////////////

                Integrari

///////////////////////////////////////////
*/

.integration-row{

    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.refresh-token-icon, .cancel-integration-icon{

    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}

.cancel-integration-icon{

    margin: 0;
    font-size: 14px;
    border: 2px solid #fff;
    padding: 6px;
    border-radius: 16px;
}


/* .integration-row .refresh-token-icon-container{

    position: relative;
    display: flex;
    align-items: center;
}

.integration-row .refresh-token-icon-container .refresh-token-icon{

    color: #4d4d4d;
    font-size: 30px;
    cursor: pointer;
} */