.wrapper-dashboard{

    position: relative;
    width: 80%;
}

.wrapper-flex{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.selected{

    color: rgb(68, 165, 255) !important;
}

.rootable-page{

    margin: 0;
    
}

.form-control{

    position: relative;
}

.gmeet-services path{

    fill: #A6A6A6 !important;
}

.gmeet-service-info path{
    
    fill: #323232 !important;
}

.zoom-icon-disabled path{

    fill: #C7C7C7 !important;
}

.wrapper-dashboard.integrari{

    width: 600px;
}

@media only screen and (max-width: 1600px)  {

    .wrapper-dashboard{

        width: 95%;
    }
}

.appoint-desc-limit{

    position: relative;
    margin: 0;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: #263238;
}

.terms-wrapper{

    display: flex;
    align-items: center;
    text-align: left;
}

.terms-checkbox, .terms-checkbox-error{

    position: relative;
    vertical-align: middle;
    cursor: pointer;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid rgb(91, 109, 118);
    border-radius: 2px;
}

.terms-checkbox-error{

    border: 2px solid #DD6A57;
}

.terms-checkbox:checked{

    border-color: rgb(94, 207, 75);
    background-color: rgb(94, 207, 75);
}

.terms-checkbox-error:checked{

    border-color: #DD6A57;
    background-color: #DD6A57;
}

.terms-checkbox:checked:after, .terms-checkbox-error:checked:after{

    position: absolute;
	top: -4px;
    color: #fff;
    content: '\2714';
}

.terms-text{

    display: block;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #263238;
}  

.terms-error{

    color: #DD6A57 !important;
}

.limit-desc-wrapper{

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 82px;
    /* line-clamp: 3; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.separator-line{

    width: 100%;
    height: 1px;
    background-color: #EBEBEB;
}

.tx-left{

    text-align: left;
}

/*
////////////////////////////////////////////

             Location Icons 

///////////////////////////////////////////
*/

/* For ReactIcons */

.loc-icon-light{

    color:  #A6A6A6
}

.loc-icon-dark{

    color: #323232;
}

.loc-icon-faded{

    color: #C7C7C7 ;
}

/* For imported svg icons */

.loc-icon-light path{

    fill: #A6A6A6 !important;
    width: 18px;
}

.loc-icon-dark path{

    fill: #323232 !important
}

.loc-icon-faded path{

    fill: #C7C7C7 !important;
}

/*
////////////////////////////////////////////

                App Bar

///////////////////////////////////////////
*/

.header-dashboard{

    display: flex;
    /* justify-content: center;
    align-items: center; */
}

.header-dashboard .left{

    display: flex;
    align-items: center;
    width: 60%;
    text-align: left;
}

.header-av{

    width: 60px;
    display: flex;

}

.header-acc{

    display: flex;
    flex-direction: column;
} 

.header-dashboard .right{

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;

}

.header-icons{

    display: flex;
    flex-direction: row;
}

.appbar-container{
    
    width: 100%;
}

.appbar-container .wrapper-dashboard{

    margin: 0 auto;
}

.company-avatar{

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}


.appbar-avatar{

    position: relative;
    top: 18px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    background-color: aqua;

}

.appbar-text{
    /* position: relative; */
    margin-top: 16px;
    /* margin-left: 10px; */
}

.appbar-text h2{

    font-weight: 500;
    font-size: 24px;
}
.appbar-text p{

    margin-top: -15px;
    color: rgb(68, 165, 255);
    font-size: 16px;
    cursor: pointer;
}

.cancel-bg{
    position: fixed; /* Stay in place */
    z-index: 9999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    /* background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4); */
}

.icon{

    position: relative;
    /* display: inline-block;
    margin-top: 30px; */
    font-size: 22px;
    color: rgb(69, 90, 100);
    cursor: pointer;
    z-index: 9999999;
}

.icon:nth-child(1){

    margin-right: 25px;
}

@media only screen and (max-width: 1200px){

    /* .appbar-container .wrapper-dashboard .row .col-s-6:nth-child(1){

        position: relative;
        top: 20px;
    } */

    /* .appbar-text{

        margin-top: 24px;
        margin-bottom: 10px;
    } */

    /* .appbar-text h2{

        font-size: 18px;
    } */

    /* .appbar-text p{

        font-size: 12px;
    } */

    .header-name{

        font-size: 18px !important;
    }

    .header-copy{

        font-size: 12px !important;
    }
}

/*
////////////////////////////////////////////

            Dashboard Nav

///////////////////////////////////////////
*/

/*Desktop*/

.dashboard-nav{
    
    width: 100%;
    height: 60px;
    background-color: #fff;
}

.dashboard-nav .wrapper-dashboard{

    position: relative;
    margin: 0 auto;
}

.dashboard-nav .wrapper-dashboard .nav-item{

    float: left;
    margin-top: 10px;
    margin-right: 40px;
    cursor: pointer;
    color: rgb(116, 131, 139);
}

.dashboard-nav .wrapper-dashboard .nav-item.back, .dashboard-nav .wrapper-dashboard .nav-item.add, .nav-item.save{

    position: absolute;
    right: 110px;
    /* float: right; */
    margin-top: 0px;
    top: 13px;
    margin-right: 0;
    font-weight: 500px;
}

.dashboard-nav .wrapper-dashboard .nav-item.add{

    right: 0;
}

.nav-item.save{

    right: 0;
    /* float: right; */
    top: -77px;
    z-index: 999;
    cursor: pointer;
}


.dashboard-nav .wrapper-dashboard .nav-item.path-service{

    margin-top: 15px;
    color: rgb(68, 165, 255);
    font-size: 18px;
    cursor: default;
}

@media only screen and (max-width: 1440px){

    .dashboard-nav .wrapper-dashboard  .nav-item.path-service{

        margin-top: 18px;
        font-size: 16px
    }
}

/*Mobile*/

.m-dsbnav-wrapper{

    position: relative;
    width: 70%;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
}


.m-nav-item{

    position: relative;
    top: 10px;
    color: rgb(116, 131, 139);
}

.m-nav-item:nth-child(1){

   float: left;
   left: -10px;
}

.m-nav-item:nth-child(2){

    float: right;
}


/*
////////////////////////////////////////////

                Inputs

///////////////////////////////////////////
*/

.input-label, .select-input-label{

    position: absolute;
    top: 1px;
    left: 15px;
    font-size: 12px;
    font-weight: 400;
    color: rgb(144, 144, 144);
}

.form-input, .select-input, .m-form-input{

    position: relative;
    padding: 10px 15px 5px 15px;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    font-size: 17px;
    border: 1px solid rgb(224, 224, 224);
    background-color: #FAFAFA; 
}

.form-input.marker{

    padding: 10px 40px 5px 15px;
}

.m-form-input{

    border-radius: 10px;
    border: 1px solid #DBDBDB;
    background-color: #fff;
}

.m-form-input::placeholder{

    color: #A6A6A6;
    font-size: 18px;
}

.m-form-input:focus{

    outline: none;
}

.form-input.days-input{

    text-align: center;
}

.m-days-input{

    padding: 5px 3px 5px 3px;
}

.select-input{

    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
}

.form-input:focus, .select-input:focus{

    outline: none;
}

.error-icon{

    color: #EB9481;
}

.verified-icon{

    color: rgb(49, 168, 29);
}

.status-icon{

    position: absolute;
    top: 22px;
    right: 15px;
}

.error-background{

    position: absolute;
    top: 50px;
    right: 6px;
    padding: 10px;
    border-radius: 5px;
    background-color: #EB9481;
    text-align: center;
    z-index: 999;
}

.input-error{
    
    margin: 0;
    font-size: 14px;
    color: #fff;
}

@media only screen and (max-width: 1200px){

    .input-label, .select-input-label{

        font-size: 10px;
        top: 6px;
    }
}

/*
////////////////////////////////////////////

            Button Form

///////////////////////////////////////////
*/

.form-button, .client-schedule-btn{

    width: 100%;
    height: 45px;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s ease-in;
    /* rgb(255, 187, 42) */
}

.client-schedule-btn{

    width: 50%;
    height: 50px;
    font-size: 16px;
}

.disabled-btn{

    background: #EBEBEB !important;
    cursor: default;
}

.facebook-btn{

    background-color: rgb(106, 154, 198);
}

.facebook-btn:hover{

    background-color: rgb(71, 138, 201);
}

.google-btn{

    background-color: rgb(238, 107, 52);
}

.google-btn:hover{

    background-color: rgb(238, 87, 22);
}

.login-btn, .client-schedule-btn{

    background-color: rgb(248, 188, 5);
}

.login-btn:hover, .client-schedule-btn:hover{

    background-color: rgb(216, 181, 5);
}


/*
////////////////////////////////////////////

                User Link

///////////////////////////////////////////
*/

.link-preview, .user-link{
    
    text-align: left;
}

.link-preview{

    color: rgb(255, 213, 93);
    font-weight: 500;
}

.user-link{

    color: rgb(170, 170, 170);
}

.user-link span{

    color: #000;
    font-weight: 500;
}

.link-options-wrapper{

    display: flex;
    justify-content: space-between;
    flex-wrap: row wrap;
}

.link-option{

    cursor: pointer;
    color: rgb(170, 170, 170);
    font-size: 17px;
}

/*
////////////////////////////////////////////

                 Location

///////////////////////////////////////////
*/

.location-card{

    position: relative;
    width: 100%;
    max-height: 60px;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    background-color: rgb(250, 250, 250);
    color: rgb(69, 90, 100);
    cursor: pointer;
}

.location-card .gmeet path{

    fill: rgb(69, 90, 100);
}

.location-icon{

    /* display: inline-block; */
    /* margin-top: 15px; */
    vertical-align: top;
    font-size: 22px;
}

.location-info{

    vertical-align: top;
}

.location-info p{

    margin-top: 5px;
    margin-left: 10px;
    font-size: 10px;
    
}

.location-info p span{

    font-size: 15px;
    font-weight: 700;
}

.sync-info p{

    font-size: 10px;
}

.sync-icon{

    font-size: 16px;
    /* margin-right: 10px; */
    color: rgb(49, 168, 29);
}

.sync-icon.connect{

    font-size: 20px;
    color: #455A64;
}

.select-location{

    position: absolute;
    top:60px;
    left:0;
    width: 100%;
    min-height: 140px;
    border-radius: 0px 0px 5px 5px;
    background-color: rgb(250, 250, 250);
    cursor: pointer;
    z-index: 1;
}

.select-loc-row{

    padding: 5px 15px 5px 15px;
    transition: 0.2s ease-in;
    border-radius: 5px;
    /* border-top: 1px solid rgb(69, 90, 100); */
}

.zoom-icon-loc{

    width: 22px;
    height: 11px;
}

.zoom-icon-loc path, .office-icon-loc path{

    fill: rgb(69, 90, 100) !important;
}

.select-loc-row:hover{

    background-color: rgb(49, 168, 29);
    color: #fff !important;
}

.select-loc-row:hover .sync-icon{

    color: #fff !important;
}

.select-loc-row:hover .gmeet path{

    fill: #fff !important;
}

.select-loc-row:hover .zoom-icon-loc path, 
.select-loc-row:hover .office-icon-loc path
{

    fill: #fff !important;
}

.m-selected-loc{

    background: #FFC727;
    color: #fff;
}

.m-selected-loc .sync-icon{

    color: #fff !important;
    opacity: 0.6;
}

.m-selected-loc .gmeet path, 
.m-selected-loc .zoom-icon-loc path,
.m-selected-loc .office-icon-loc path
{

    fill: #fff !important;
}

.selected-integration{

    background-color: #60D84C;
    color: #fff !important;
}

.selected-integration .sync-icon{

    color: #fff !important;
}

@media only screen and (max-width: 1200px)  {

    .location-info p span{

        font-size: 12.5px;
        font-weight: 700;
    }
    
}

/*
////////////////////////////////////////////

           Textarea Description

///////////////////////////////////////////
*/

.service-description{

    position: relative;
    width: 100%;
    border: 1px solid rgb(224, 224, 224);
    background-color: rgb(250, 250, 250);
    border-radius: 5px;
    resize: none;
    padding: 15px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    overflow-y: scroll;
}

.service-description:focus{

    outline: none;
}

.service-description-icon{
    margin-top: 5px;
    position: absolute;
    margin: 0;
    bottom: 15px;
    left: 15px;
    font-size: 20px;
    cursor: pointer;
    transition: 0.2s ease-in;
}

.italic-icon{

    left: 40px;
}

.underline-icon{

    bottom: 14px;
    left: 65px;
}

.service-description-icon:hover{

    color: rgb(255, 213, 93);
}

.service-description-limit{

    position: absolute;
    left: 0;
    bottom: -25px;
    font-size: 12px;
    color: rgb(91, 109, 118);
}

/*
////////////////////////////////////////////

            Mobile Service Nav

///////////////////////////////////////////
*/

.m-serv-nav-container{

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background: #fff;
    padding: 20px;
}
.m-serv-nav-container .back-btn{

    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 22px;
    color: #455A64;
}

/*
////////////////////////////////////////////

            Mobile Switch

///////////////////////////////////////////
*/


.switch, .dsk-switch {
    position: relative;
    display: inline-flex;
    margin: 0;
    right: 0;    
}
  
.switch input[type=checkbox], .dsk-switch input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
}

.switch input[type=checkbox]:checked + label, 
.dsk-switch input[type=checkbox]:checked + label {
    background: #31A81D;
}

.switch input[type=checkbox]:checked + label::after, 
.dsk-switch input[type=checkbox]:checked + label::after 
{
    left: calc(100% - 4px);
    transform: translateX(-100%);
}

.switch label, .dsk-switch label {
    cursor: pointer;
    width: 48px;
    height: 24px;
    background: grey;
    display: block;
    border-radius: 24px;
    position: relative;
}

.switch label::after, .dsk-switch label::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 16px;
    transition: 0.3s;
}

.dsk-switch label{

    width: 34px;
    height: 17px;
}

.dsk-switch label::after{

    top: 3px;
    width: 10px;
    height: 10px;
} 

/*
////////////////////////////////////////////

            AVATAR UPLOADER 

///////////////////////////////////////////
*/

.onboarding-avatar{

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    width: 200px;
    height: 200px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    /* box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25); */
    border: 8px solid rgb(246, 246, 246);
    border-radius: 50%;
}

.onboard-center-img{

    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}

.onboard-avatar-img{

    width: 100%;
    height: 100%;
}   

.avatar-dimensions{

    margin: 20px auto;
    width: 50%;
    text-align: center;
    font-size: 12px;
    color: #263238;
}

.avatar-uploader{

    position: absolute;
    bottom: 5px;
    right: -10px;
    width: 65px;
    height: 65px;
    background-color: rgb(246, 246, 246);
    border-radius: 50%;
    cursor: pointer;
}

.avatar-uploader input{

    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 999;
}

.avatar-uploader-icon{

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 26px;
    color: rgb(50, 50, 50);
    z-index: 0;
}

/*
////////////////////////////////////////////

                Integrations

///////////////////////////////////////////
*/

.integrations-card{

    display: flex;
    align-items: center;
    width: 100%;
    /* height: 77px; */
    padding: 14px;
    background: #F3F3F3;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    cursor: pointer;
}

 .integrations-card .icon-wrapper{

    display: flex;
    align-items: center;
    width: 65%;
}

.integrations-card .icon-wrapper .integrations-icon{

    color: #455A64;
}

.integrations-card .icon-wrapper .integrations-icon.zoom{

    width: 24px;
    height: 14px;
}


.integrations-card .icon-wrapper .integrations-icon.skype{

    font-size: 22px;
}

.integrations-card .icon-wrapper .integrations-icon.gmeet{

    width: 26px;
    height: 26px;
}

.integrations-card .icon-wrapper .integrations-icon.netopia,
.integrations-card .icon-wrapper .integrations-icon.smartbill
{

    font-size: 20px;
}


.integrations-card .icon-wrapper .integrations-icon.zoom path,
.integrations-card .icon-wrapper .integrations-icon.gmeet path
{ 

    fill: #455A64;
}

.integrations-card .icon-wrapper .integrationsTx-wrapper{

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 14px;
    text-align: left;
}

.integrations-card .icon-wrapper .integrationsTx-wrapper .integrationsTx{

    color: #455A64;
    font-weight: 400;
}

.integrations-card .icon-wrapper .integrationsTx-wrapper .integrationsTx.subtitle{

    font-size: 10px;
}

.integrations-card .icon-wrapper .integrationsTx-wrapper .integrationsTx.title{

    font-size: 14px;
    font-weight: 500;
}

.integrations-card .status-wrapper{

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 35%;
}

.integrations-card .status-wrapper .statusTx{

    display: flex;
    align-items: center;
    flex-direction: column;
}

.integrations-card .status-wrapper .statusTx .integrations-icon.verified{

    font-size: 20px;
    opacity: .7;
    color: #fff;
}

.integrations-card .status-wrapper .statusTx .integrations-icon.connect{

    font-size: 26px;
    color: #323232;
}

.integrations-card .status-wrapper .statusTx .integrationsTx.status{

    position: relative;
    top: 4px;
    font-size: 10px;
    color: #323232;
}

.integrations-card .status-wrapper .integrations-icon.arrow{
    
    position: relative;
    left: 6px;
    font-size: 28px;
    color: #323232;
}

/*========Synced========*/

.integrations-card.synced{

    background-color: #31A81D;
    color: #fff !important;
}

.integrations-card.synced .icon-wrapper .integrations-icon{

    color: #fff !important;
}

.integrations-card.synced .icon-wrapper .integrations-icon.zoom path,
.integrations-card.synced .icon-wrapper .integrations-icon.gmeet path,
.integrations-card.synced .icon-wrapper .integrations-icon.smartbill path
{ 

    fill: #fff !important;
}

.integrations-card.synced .icon-wrapper .integrationsTx-wrapper .integrationsTx{

    color: #fff;
}

.integrations-card.synced .status-wrapper .statusTx .integrationsTx.status{

    color: #fff;
}

.integrations-card.synced .status-wrapper .integrations-icon.arrow{

    color: #fff;
}

/* ////// Subscription Icons ////// */

.integrations-card .icon-wrapper .integrations-icon.sub{

    font-size: 26px;
}

/*
////////////////////////////////////////////

                    Team

///////////////////////////////////////////
*/

.teamMember-card{

    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px;
    background-color: #FAFAFA;
    border-radius: 5px;
    border: 1px solid #EBEBEB;
    cursor: pointer;
}

.teamMember-card.selected{

    background-color: #44a5ff5d;
    border: 1px solid #44A5FF;
}

.teamMember-card .left{

    display: flex;
    align-items: center;
    width: 90%;
}

.teamMember-card .left .member-avatar-wrapper{

    display: flex;
    align-items: center;
    width: 68px;
}

.teamMember-card .left .member-avatar-wrapper .member-avatar{

    width: 54px;
    height: 54px;
    background-color: #FFC727;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
}

.teamMember-card .left .member-info{

    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: left;
}

.teamMember-card .left .member-info .infoTx{

    margin: 0;
    font-weight: 400;
    /*#263238*/
}

.teamMember-card .left .member-info .infoTx.name,
.teamMember-card .left .member-info .infoTx.desc
{

    color: #263238;
}

.teamMember-card .left .member-info .infoTx.name{

    font-size: 14px;
}

.teamMember-card .left .member-info .infoTx.desc{

    font-size: 10px;
    color: #777777;
}

.teamMember-card .left .member-info .infoTx.schedule{

    font-size: 10px;
}

.teamMember-card .right{

    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
}

.teamMember-card .right .teamMember-enter{

    position: relative;
    left: 6px;
    font-size: 26px;
    color: #44A5FF;
}

.teamMember-add{

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FFC727;
    cursor: pointer;
}

.teamMember-add .teamMember-icon{

    font-size: 20px;
    color: #fff;
}

/*
////////////////////////////////////////////

                  Loader

///////////////////////////////////////////
*/


.loading-wrapper {
    /* width: 100vw;
    height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bar {
    display: block;
    height: 36px;
    width: 10px;
    background: #F8BC05;
    margin-right: 24px;
    animation: scale 1.6s ease-in-out infinite;
    transform-origin: 50% 50%;
    box-shadow: 2px 2px 2px #745908;
  }
  
  .bar.one {
    animation-delay: 1s;
  }
  
  .bar.two {
    animation-delay: 1.2s;
  }
  
  .bar.three {
    animation-delay: 1.3s;
  }
  
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  /*DOT LOADER*/

  .container {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); */

    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
  }
  
  .loader {
    display: flex;
    align-items: center;
  }
  
  .loader-shap {
    width: 32px;
    height: 32px;
    background-color: #ffde59;
    margin: 1rem;
    border-radius: 50%;
    animation: load_shap 1.2s ease infinite;
  }
  
  .loader-shap:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .loader-shap:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .loader-shap:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes load_shap {
    50% {
      transform: translateY(-50%);
      border-bottom: 16px solid #292929;
      box-shadow: 0 8rem 2rem rgba(255, 222, 89, 0.24);
    }
}


/*CIRCLE SPINNER*/

.circle-loader {
    position: relative;
    width: auto;
    height: auto;
  }
  
  .circle-loader div {
    height: 10px;
    width: 10px;
    background-color: rgb(116, 131, 139);
    border-radius: 50%;
    position: absolute;
    -webkit-animation: 0.8s opaque ease-in-out infinite both;
    animation: 0.8s opaque ease-in-out infinite both;
  }
  
  .circle-loader > div:nth-child(1) {
    top: -25px;
    left: 0;
  }
  .circle-loader > div:nth-child(2) {
    top: -17px;
    left: 17px;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  .circle-loader > div:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  .circle-loader > div:nth-child(4) {
    top: 17px;
    left: 17px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .circle-loader > div:nth-child(5) {
    top: 25px;
    left: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .circle-loader > div:nth-child(6) {
    top: 17px;
    left: -17px;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .circle-loader > div:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .circle-loader > div:nth-child(8) {
    top: -17px;
    left: -17px;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }

  @-webkit-keyframes opaque {
    0% {
      opacity: 0.3;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.3;
    }
  }
  
  @keyframes opaque {
    0% {
      opacity: 0.3;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.3;
    }
  }

  /* DESKTOP BOTTOM PAGE IMAGE */

.bottom-pg-img{

    display: block;
    margin: 0 auto;
    width: 340px;
}


.carousel.carousel-slider {
    margin: 0px auto;
    overflow: visible;
  }
  
