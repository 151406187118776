/*Welcome*/

.welcome-container{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

/*Login*/

.login-container{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.login-card{

    position: relative;
    width: 100%;
    /* width: 470px; */
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
}


.form-control{

    position: relative;
}

.signin-link{

    font-size: 16px;
    color: rgb(63, 168, 208);
    transition: 0.2s ease-in;
}

.signin-link:hover{

    color: rgb(6, 105, 145);
}

.no-account{

    margin-bottom: -5px;
    color: rgb(145, 145, 145);
    font-size: 16px;
}

@media only screen and (max-width: 1200px){


    .login-card{

        background: none;
        box-shadow: 'none';
    }
}
