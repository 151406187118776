.App {

    height: 100%;
    text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[class*='col-'] {
  float: left;
  /* padding: 15px; */
  /*border: 1px solid red;*/
}

.row::after {
  content: '';
  clear: both;
  display: table;
}


[class*='col-'] {
  width: 100%;
}


@media only screen and (max-width: 1200px)  {
  /* For tablets: */
  .col-s-1 {
      width: 8.33%;
  }

  .col-s-2 {
      width: 16.66%;
  }

  .col-s-3 {
      width: 25%;
  }

  .col-s-4 {
      width: 33.33%;
  }

  .col-s-5 {
      width: 41.66%;
  }

  .col-s-6 {
      width: 50%;
  }

  .col-s-7 {
      width: 58.33%;
  }

  .col-s-8 {
      width: 66.66%;
  }

  .col-s-9 {
      width: 75%;
  }

  .col-s-10 {
      width: 83.33%;
  }

  .col-s-11 {
      width: 91.66%;
  }

  .col-s-12 {
      width: 100%;
  }

  .col-s-barav{

    width: 60px;
  }

  .col-s-bartx{

    width: 110px;
  }
}

@media only screen and (min-width: 1201px) {
  /* For desktop: */
  .col-m-1 {
      width: 8.33%;
  }

  .col-m-2 {
      width: 16.66%;
  }

  .col-m-3 {
      width: 25%;
  }

  .col-m-4 {
      width: 33.33%;
  }

  .col-m-5 {
      width: 41.66%;
  }

  .col-m-6 {
      width: 50%;
  }

  .col-m-7 {
      width: 58.33%;
  }

  .col-m-8 {
      width: 66.66%;
  }

  .col-m-9 {
      width: 75%;
  }

  .col-m-10 {
      width: 83.33%;
  }

  .col-m-11 {
      width: 91.66%;
  }

  .col-m-12 {
      width: 100%;
  }

  .col-m-93{

    width: 93%;
  }

  .col-m-07{
    width: 7%;
  }

}

@media only screen and (min-width: 1920px) {
  /* For desktop: */
  .col-l-1 {
      width: 8.33%;
  }

  .col-l-2 {
      width: 16.66%;
  }

  .col-l-3 {
      width: 25%;
  }

  .col-l-4 {
      width: 33.33%;
  }

  .col-l-5 {
      width: 41.66%;
  }

  .col-l-6 {
      width: 50%;
  }

  .col-l-7 {
      width: 58.33%;
  }

  .col-l-8 {
      width: 66.66%;
  }

  .col-l-9 {
      width: 75%;
  }

  .col-l-10 {
      width: 83.33%;
  }

  .col-l-11 {
      width: 91.66%;
  }

  .col-l-12 {
      width: 100%;
  }

  .col-l-94{

    width: 94%;
  }

  .col-l-06{
    width: 6%;
  }
}

@media only screen and (min-width: 3000px) {
  /* For desktop: */
  .col-xl-1 {
      width: 8.33%;
  }

  .col-xl-2 {
      width: 16.66%;
  }

  .col-xl-3 {
      width: 25%;
  }

  .col-xl-4 {
      width: 33.33%;
  }

  .col-xl-5 {
      width: 41.66%;
  }

  .col-xl-6 {
      width: 50%;
  }

  .col-l-7 {
      width: 58.33%;
  }

  .col-xl-8 {
      width: 66.66%;
  }

  .col-xl-9 {
      width: 75%;
  }

  .col-xl-10 {
      width: 83.33%;
  }

  .col-xl-11 {
      width: 91.66%;
  }

  .col-xl-12 {
      width: 100%;
  }
}
