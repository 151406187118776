body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track{
  /* background: #f1f1f1;  */
  background: none;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  
  background: #888; 
  border-radius: 5px;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


html {
  box-sizing: border-box;
  font-family: 16px sans-serif;
  background:  #F6F6F6;
}

html, body{
  
  height:100%;
}

*, *:before, *:after {
  
  box-sizing: inherit;

}



