.register-container-desktop{

    margin: 20px auto;
    width: 700px;
}

/*
///////// Header ///////

*/

.register-header{

    width: 100%;
    display: flex;
    align-items: center;
}

.register-header .avatar{

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
}

.register-header .avatar .reg-avatar-image{

    display: block;
    width: 50px;
    height: 50px;
}

.register-header .avatar .reg-avatar-tx{

    position: relative;
    left: 8px;
    color: #455A64;
    font-size: 18px;
    font-weight: 500;
}

.register-header .icons{

    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.register-header .icons .reg-icon{

    position: relative;
    color: #C7C7C7;
    font-size: 20px;
}

.register-header .icons .reg-icon:nth-child(1){

    right: 22px;
    font-size: 22px;
}

/*
///////// Pages Content ///////

*/

.register-tx{

    margin: 0;
    color:#263238;
    font-size: 16px;
}

.sm{

    font-size: 14px;
}


.register-title{

    font-weight: 500;
}

.bold{

    font-weight: 700;
}

.register-image{

    display: block;
    width: 100%;
}

.register-next-arrow{

    display: inline-block;
    position: relative;
    top: 2px;
    font-size: 20px;
}

.register-center-tx-wrapper{

    margin: 0 auto;
    width: 295px;
}

.register-center-tx-wrapper.success-acc{

    width: 440px;
}

.register-btn-container{

    margin-top: 20px;
    display: flex;
    align-items: center;
    text-align: center;
}

.register-btn-container .continue-btn-container{

    width: 80%;
    padding-left: 16px;
}

.register-btn-container .back-btn-container{

    width: 20%;
}

.register-btn-container .back-btn-container .back-btn{

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 58px;
    height: 58px;
    background-color: #F4F4F4;
    font-size: 24px;
    color: #323232;
}

.pagination-container{

    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60px;
}

.swiper-pagination-bullet{

    width: 10px !important;
    height: 10px !important;
    color: #C4C4C4 !important;
}

.swiper-pagination-bullet-active{

    background-color: #FFC727 !important;
}

